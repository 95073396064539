@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');

/* ================================
   🌴 Bakgrund i Amazon/Jumanji-stil
================================ */
.view-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #0f2e1e;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 0;
}

/* ================================
   💻 Jungle Positionering
================================ */
.nav-item.about {
  top: 22%;
  left: 9%;
  transform: rotate(-2deg);
}

.nav-item.education {
  top: 41%;
  right: 11%;
  transform: rotate(1.5deg);
}

.nav-item.experience {
  bottom: 30%;
  left: 18%;
  transform: rotate(-3deg);
}

.nav-item.contact {
  bottom: 12%;
  right: 14%;
  transform: rotate(2deg);
}

/* ================================
   🪵 Jungle Menystil
================================ */
.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 16px 30px;
  border: 3px solid rgba(0, 128, 64, 0.25);
  border-radius: 16px;
  background: linear-gradient(145deg, #1a2d1a, #0f1c0f);
  backdrop-filter: blur(3px);
  color: #b2ffc4;
  font-weight: 700;
  font-family: 'Cinzel Decorative', serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 6px #003322;
  box-shadow:
    inset 0 0 8px rgba(0, 128, 64, 0.2),
    0 0 14px rgba(0, 255, 128, 0.15),
    0 6px 20px rgba(0, 128, 64, 0.2);
  transition: all 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
  z-index: 5;
}

.nav-item span {
  color: inherit;
  font-size: 16px;
}

.nav-item:hover {
  background: linear-gradient(145deg, #233c23, #162f16);
  border-color: #6cffb5;
  box-shadow:
    0 0 16px rgba(0, 255, 153, 0.3),
    0 0 8px rgba(100, 255, 195, 0.3),
    0 6px 30px rgba(0, 128, 64, 0.4);
  color: #ffffff;
  text-shadow: 0 0 12px #00ffbf;
  transform: scale(1.05);
}

.nav-item.active {
  border-color: #38ffa8;
  background: #0f2910;
  color: #ffffff;
  box-shadow:
    inset 0 0 12px #38ffa866,
    0 0 10px #38ffa866;
  transform: scale(1.08);
}

/* ================================
   🪟 Paneloverlay – magisk portal
================================ */
.panel-container {
  display: block;
  position: fixed;
  inset: 0;
  z-index: 999;
  background: radial-gradient(ellipse at center, rgba(5, 25, 15, 0.95), rgba(0, 0, 0, 0.98));
  color: #dfffe4;
  overflow-y: auto;
  padding: 3rem 2rem;
  animation: fadeIn 0.4s ease-in-out;
  font-family: 'Poppins', sans-serif;
  box-shadow:
    inset 0 0 100px rgba(0, 255, 128, 0.08),
    0 0 40px rgba(0, 255, 170, 0.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.96) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* ================================
   🖱 Cursor toggle (styr från View.js)
================================ */
body,
.character-container {
  cursor: none;
}

body.show-cursor,
.character-container.show-cursor {
  cursor: default;
}
