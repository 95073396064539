@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #000;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  cursor: none;
}

/* ================================
   🌌 Trail Effekt (muspekar-effekt)
================================ */
.trail-dot {
  position: fixed;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: radial-gradient(circle, #00ffff 0%, #005555 100%);
  pointer-events: none;
  z-index: 100;
  filter: blur(6px) drop-shadow(0 0 12px #00ffffaa);
  animation: pulseTrail 0.6s ease-out infinite;
  transition: transform 0.2s, opacity 0.2s ease;
}

@keyframes pulseTrail {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
}

/* ================================
   🧭 Navigation – Jumanji Style
================================ */
.portfolio-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
}

.nav-links {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 16px 28px;
  border: 3px solid rgba(114, 255, 161, 0.4);
  border-radius: 16px;
  background: linear-gradient(145deg, #1a2d1a, #0f1c0f);
  backdrop-filter: blur(2px);
  color: #aefecb;
  font-weight: 700;
  font-family: 'Cinzel Decorative', serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 6px #001e00;
  box-shadow:
    inset 0 0 10px rgba(0, 128, 0, 0.2),
    0 0 12px rgba(0, 255, 128, 0.1),
    0 6px 30px rgba(0, 128, 64, 0.25);
  transition: all 0.25s ease;
  transform: rotateZ(-1deg);
  cursor: pointer;
}

.nav-item:hover {
  background: linear-gradient(145deg, #223c22, #152f15);
  border-color: #6cffb5;
  box-shadow:
    0 0 20px rgba(0, 255, 153, 0.3),
    0 0 8px rgba(100, 255, 195, 0.3),
    0 6px 30px rgba(0, 128, 64, 0.4);
  transform: rotateZ(0deg) scale(1.05);
  color: #ffffff;
  text-shadow: 0 0 12px #00ffbf;
}

.nav-item.active {
  background: #0f2910;
  border-color: #38ffa8;
  color: #ffffff;
  box-shadow:
    inset 0 0 12px #38ffa866,
    0 0 10px #38ffa866;
  transform: scale(1.08);
}

/* Positionering */
.nav-item.about     { top: 150px; left: 190px; }
.nav-item.education { top: 190px; right: 140px; }
.nav-item.experience { bottom: 300px; left: 130px; }
.nav-item.contact   { bottom: 320px; right: 160px; }

/* ================================
   📎 Footer
================================ */
.footer-info {
  color: #00ffff;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.25);
  padding: 8px 10px;
  text-shadow: 0 0 4px #00ffff;
  backdrop-filter: blur(4px);
  box-shadow: 0 -2px 10px rgba(0, 255, 255, 0.2);
  width: 100%;
  position: fixed;
}

.bottom-footer {
  bottom: 0;
  top: auto;
  border-top: 1px solid rgba(0, 255, 255, 0.2);
  border-bottom: none;
}

.bottom-footer a {
  color: #00ffff;
  text-decoration: none;
  margin: 0 6px;
  font-weight: bold;
}

.bottom-footer a:hover {
  text-shadow: 0 0 8px #00ffff;
}

/* ================================
   📱 Mobilanpassning
================================ */
@media (max-width: 600px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .nav-item {
    position: static !important;
    width: 70vw;
    max-width: 300px;
    text-align: center;
    margin: 8px 0;
  }

  .footer-info {
    font-size: 9px;
  }
}
