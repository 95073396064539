.jungle-about-wrapper {
  padding: 40px 28px;
  background: linear-gradient(145deg, #0d2214, #07140b);
  border: 3px solid rgba(0, 128, 64, 0.3);
  border-radius: 18px;
  color: #e2ffe9;
  font-family: 'Poppins', sans-serif;
  max-width: 900px;
  margin: 60px auto;
  position: relative;
  box-shadow:
    0 0 20px rgba(0, 255, 170, 0.08),
    inset 0 0 40px rgba(0, 128, 64, 0.2);
  animation: jungleFadeIn 0.5s ease-in-out;
  backdrop-filter: blur(6px);
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 255, 170, 0.05);
  border: 1px solid rgba(0, 255, 170, 0.3);
  color: #00ffc3;
  border-radius: 8px;
  font-size: 18px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.close-btn:hover {
  background: rgba(0, 255, 170, 0.1);
  box-shadow: 0 0 8px #00ffc3aa;
}

.about-content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

/* Profilbild */
.profile-img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 12px;
  border: 2px solid rgba(0, 255, 170, 0.3);
  box-shadow: 0 0 14px rgba(0, 255, 170, 0.15);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.profile-img.loaded {
  opacity: 1;
}

/* Textkolumn */
.text-info {
  flex: 1;
  min-width: 260px;
}

.text-info h2 {
  margin-bottom: 16px;
  font-size: 2rem;
  color: #7effc6;
  text-shadow: 0 0 10px rgba(0, 255, 170, 0.3);
}

.text-info p {
  font-size: 1.05rem;
  line-height: 1.6;
  color: #c8ffdc;
  margin-bottom: 18px;
  text-shadow: 0 0 4px rgba(0, 128, 64, 0.1);
}

/* CV-knapp */
.download-btn {
  display: inline-block;
  margin-top: 12px;
  padding: 12px 24px;
  background: rgba(0, 255, 170, 0.07);
  border: 2px solid #00ffbf;
  border-radius: 10px;
  color: #b6ffe4;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.25s ease;
  text-shadow: 0 0 4px #00ffbf80;
}

.download-btn:hover {
  background: rgba(0, 255, 170, 0.1);
  transform: scale(1.05);
  box-shadow: 0 0 16px #00ffc3cc;
}

/* Animation */
@keyframes jungleFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* 📱 Mobilvänlig */
@media (max-width: 600px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-img {
    width: 120px;
    height: 120px;
  }

  .text-info h2 {
    font-size: 1.6rem;
  }

  .text-info p {
    font-size: 0.95rem;
  }

  .download-btn {
    padding: 10px 20px;
  }
}
